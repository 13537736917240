import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import EventInfo from '../components/EventInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import ModularBlocks from '../components/ModularBlocks';
import SocialShare from '../components/SocialShare';
import BlogListing from '../components/BlogListing';

const BlogSingleTemplate = ({
  data: {
    datoCmsBlog: {
      seoMetaTags,
      title,
      category,
      bannerImage,
      meta: { firstPublishedAt },
      eventDateTime,
      eventPrices,
      eventLocation,
      eventBookingLink,
      modularBlocks,
    },
    allDatoCmsBlog,
  },
  location: pageData,
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={`Published: ${firstPublishedAt}`}
        tag={category.name}
        isEventBlogSingle={category.name === 'Events'}
      />
      {category.name === 'Events' && (
        <EventInfo
          dateTime={eventDateTime}
          prices={eventPrices}
          location={eventLocation}
          bookingLink={eventBookingLink}
        />
      )}
      <Breadcrumbs
        currentPageTitle={title}
        items={[
          {
            urlPath: 'blog',
            text: 'Blog',
          },
        ]}
        isBlogSingle={true}
      />
      <ModularBlocks items={modularBlocks} isBlogSingle={true} />
      <SocialShare
        heading="Share Post:"
        pageTitle={title}
        pageData={pageData}
      />
    </main>
    <BlogListing heading="Other Blog Posts" items={allDatoCmsBlog.nodes} />
  </Layout>
);

export const BlogSingleTemplateQuery = graphql`
  query BlogSingleTemplateQuery($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      category {
        name
      }
      bannerImage {
        ...SubPageBannerImageFragment
      }
      meta {
        firstPublishedAt(formatString: "DD MMM yyyy")
      }
      eventDateTime
      eventPrices
      eventLocation
      eventBookingLink
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentFeaturedPointsModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedActivitiesModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageCtaModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImagesContentModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    allDatoCmsBlog(
      filter: { id: { ne: $id } }
      sort: { order: DESC, fields: meta___firstPublishedAt }
      limit: 4
    ) {
      nodes {
        ...BlogListingItemFragment
      }
    }
  }
`;

export default BlogSingleTemplate;
