import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Container, Svg, Button } from './ui';
import calendarIcon from '../images/calendar-icon.inline.svg';
import coinsIcon from '../images/coins-icon.inline.svg';
import locationIcon from '../images/location-icon.inline.svg';

const StyledEventInfo = styled.section`
  position: relative;
  margin: -20px 0 10px;

  ${minBreakpointQuery.tiny`
    margin-top: -30px;
    margin-bottom: 15px;
  `}

  ${minBreakpointQuery.small`
    margin-top: -40px;
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: -50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: -60px;
  `}
`;

const StyledContainer = styled(Container)`
  max-width: 1060px;

  ${minBreakpointQuery.large`
    max-width: 1080px;
  `}

  ${minBreakpointQuery.xxlarge`
    max-width: 1100px;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledItems = styled.div`
  display: flex;
  gap: 25px;
  padding: 30px 30px ${({ hasButton }) => (hasButton ? '45px' : '30px')};
  background-color: ${brandColours.secondary};
  border-radius: 20px;

  ${maxBreakpointQuery.smedium`
    flex-direction: column;
  `}

  ${minBreakpointQuery.large`
    gap: 35px;
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 45px;
    padding-right: 50px;
    padding-left: 50px;
  `}
`;

const StyledItem = styled.div`
  ${minBreakpointQuery.smedium`
    flex: 1;
  `}
`;

const StyledHeading = styled.h2`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${fontSize(18)};
`;

const StyledIcon = styled(Svg)`
  margin-right: 10px;
  fill: ${brandColours.primary};
  height: 20px;
  width: 20px;
`;

const StyledText = styled.p`
  ${fontSize(14)};
  line-height: 1.7;
  white-space: break-spaces;
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin-top: -28px;
  transition: ${standardTransition('transform')};

  &:hover {
    color: ${standardColours.white};
    background-color: ${brandColours.primary};
    transform: scale(1.1);
  }
`;

const EventInfo = ({ dateTime, prices, location, bookingLink }) =>
  (dateTime || prices || location) && (
    <StyledEventInfo>
      <StyledContainer>
        <StyledInner>
          <StyledItems hasButton={bookingLink}>
            {dateTime && (
              <StyledItem>
                <StyledHeading>
                  <StyledIcon image={calendarIcon} /> Date and Time
                </StyledHeading>
                <StyledText>{dateTime}</StyledText>
              </StyledItem>
            )}
            {prices && (
              <StyledItem>
                <StyledHeading>
                  <StyledIcon image={coinsIcon} /> Prices
                </StyledHeading>
                <StyledText>{prices}</StyledText>
              </StyledItem>
            )}
            {location && (
              <StyledItem>
                <StyledHeading>
                  <StyledIcon image={locationIcon} /> Location
                </StyledHeading>
                <StyledText>{location}</StyledText>
              </StyledItem>
            )}
          </StyledItems>
          {bookingLink && (
            <StyledButton href={bookingLink} alt={true}>
              Book Now
            </StyledButton>
          )}
        </StyledInner>
      </StyledContainer>
    </StyledEventInfo>
  );

export default EventInfo;
